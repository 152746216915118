import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.js";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import WhatIsRoutine from "posts/routines-channels-golang/components/WhatIsRoutine";
import PageQuoteText from "utils/pageQuoteText";
import HowToMakeRoutine from "posts/routines-channels-golang/components/HowToMakeRoutine";
import AllowingCommunication from "posts/routines-channels-golang/components/AllowingCommunication";
import RoutineChannelsFinished from "posts/routines-channels-golang/components/RoutineChannelsFinished";
export const routinesChannelGolang = graphql`
  query RoutinesChannelGolang($pagePath: String!) {
    mdx(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        path
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImg {
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  routinesChannelGolang,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageQuoteText mdxType="PageQuoteText">
  Today we'll be going over how we can take Golang's synchonous nature and turn
  it asynchronous by splitting its main routine into child routines, and making
  use of the channels that get exposed from within.
    </PageQuoteText>
    <WhatIsRoutine mdxType="WhatIsRoutine" />
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "language-go"
      }}><code parentName="pre" {...{
          "className": "language-go"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`package`}</span>{` main

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"fmt"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"net/http"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
links `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://www.google.com"`}</span>{`
`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://www.twitter.com"`}</span>{`
`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://www.youtube.com"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` link `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`range`}</span>{` links `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`checkLinks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`checkLinks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`link `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
`}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`get`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
fmt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Printf`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"%s might be down!\\n"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

fmt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Printf`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"%s is online\\n"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <p>{`What's happening here is that when Go loops through the slice of links and inevitably executes the http request for the link, the main routine that gets created goes to sleep and waits until it gets a response. Then it keeps continuing until there aren't any links left, resulting in:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`
https://www.google.com is online`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
https://www.twitter.com is online`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
https://www.youtube.com is online`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
`}</code></pre></div>
    <p>{`As you can see, no matter how many times we run the program like this, it will `}<em parentName="p">{`always`}</em>{` be completely synchronous.`}</p>
    <HowToMakeRoutine mdxType="HowToMakeRoutine" />
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "language-go"
      }}><code parentName="pre" {...{
          "className": "language-go"
        }}>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`package`}</span>{` main

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"fmt"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"net/http"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  links `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://www.google.com"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://www.twitter.com"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://www.youtube.com"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` link `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`range`}</span>{` links `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// add the go keyword before the function is invoked`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// and that's it!`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`go`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`checkLinks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`checkLinks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`link `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`get`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    fmt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Printf`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"%s might be down!\\n"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  fmt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Printf`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"%s is online\\n"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <p>{`But there's a problem, because when we run the program, nothing happens. This is because when the main routine creates every child routine, it looks through the rest of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`main`}</code>{` function and sees if there's anything left to do. Now in this particular case there isn't, so it just exits before the child routines can finish.`}</p>
    <AllowingCommunication mdxType="AllowingCommunication" />
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "language-go"
      }}><code parentName="pre" {...{
          "className": "language-go"
        }}>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`package`}</span>{` main

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"fmt"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"net/http"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  links `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://www.google.com"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://www.twitter.com"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://www.youtube.com"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// initalize the channel and set its type`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// this tells the channel what type to use for communication`}</span>{`
  c `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`make`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`chan`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` link `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`range`}</span>{` links `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// pass the channel in the function so it has access`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`go`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`checkLinks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`len`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`links`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` i`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`++`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// print whatever gets passed in the channel for each link`}</span>{`
    fmt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Println`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<-`}</span>{`c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// add the channel as an argument`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`checkLinks`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`link `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` c `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`chan`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`_`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`get`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    fmt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Printf`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"%s might be down!\\n"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// pass the value into the channel`}</span>{`
    c `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Might be down I think"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  fmt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Printf`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"%s is online\\n"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  c `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Yup its up"`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}</code></pre></div>
    <p>{`Now when we execute this program:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`
https://www.twitter.com is online`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
Yup its up

https://www.youtube.com is online`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
Yup its up

https://www.google.com is online`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
Yup its up
`}</code></pre></div>
    <RoutineChannelsFinished mdxType="RoutineChannelsFinished" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      